@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "../node_modules/@vericode/ui-library/src/assets/styles/colors.scss";

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter.ttf");
}

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --error-color: #{$error-color};
  --success-color: #{$success-color};
  --warning-color: #{$warning-color};
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

html,
body {
  margin: 0;
  width: 100vw;
  min-height: 100vh;
}

button {
  border: 0;
}

th {
  text-transform: uppercase;
}

.content-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .content-menu {
    nav {
      display: flex;
      .content-menu-button {
        z-index: 1;
        cursor: pointer;
        padding: 0px 30px;
        height: 47px;
        display: flex;
        margin-bottom: -2px;
        color: #525151;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        justify-content: center;
        align-items: center;
        background-color: #f3f0f0;
        border-radius: 10px 10px 0px 0px;
        -webkit-border-radius: 10px 10px 0px 0px;
        -moz-border-radius: 10px 10px 0px 0px;
        -ms-border-radius: 10px 10px 0px 0px;
        -o-border-radius: 10px 10px 0px 0px;

        &.active {
          z-index: 3;
          color: black;
          background-color: white;
          border-width: 2px 2px 0px 2px;
          border-style: solid;
          border-color: #64b7e3;
          border-radius: 10px 10px 0px 0px;
          -webkit-border-radius: 10px 10px 0px 0px;
          -moz-border-radius: 10px 10px 0px 0px;
          -ms-border-radius: 10px 10px 0px 0px;
          -o-border-radius: 10px 10px 0px 0px;
        }
      }
    }
  }

  .content-card {
    z-index: 2;
    width: 100%;
    min-height: 200px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 20px;
    border: 2px solid #64b7e3;
    background-color: white;
    border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -ms-border-radius: 10px 10px 10px 10px;
    -o-border-radius: 10px 10px 10px 10px;
    .content-description {
      gap: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .description-title {
        color: black;
        font-size: 20px;
        font-weight: 700;
      }

      .description {
        p {
          font-size: 12px;
          font-weight: 400;
          color: #525151;
        }
      }
    }
    .content-buttons {
      .row {
        justify-content: flex-end;
        gap: 20px;
      }
    }
  }
}

.dfc {
  display: flex;
  flex-direction: column !important;
}

.dfr {
  display: flex;
  flex-direction: row;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

form,
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .form-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 5px;
    &.switch-row {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
    }
    label {
      color: black;
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
    }
    .input-prevalue {
      position: relative;
      .prevalue {
        position: absolute;
        width: 82px;
        height: 100%;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        background: #f9f7f7;
        border: 1px solid #cecccc;
        border-radius: 5px 0px 0px 5px;
        -webkit-border-radius: 5px 0px 0px 5px;
        -moz-border-radius: 5px 0px 0px 5px;
        -ms-border-radius: 5px 0px 0px 5px;
        -o-border-radius: 5px 0px 0px 5px;
        &.error {
          border: 1px solid #d66a71;
        }
      }
      input {
        padding-left: 90px !important;
      }
    }
    input,
    select {
      width: 330px;
      font-size: 12px;
      height: 36px;
      padding: 0px 0px 0px 20px;
      background-color: white;
      color: black;
      border: 1px solid #cecccc;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      &.error {
        border: 1px solid #d66a71;
        background: #d66a7126;
      }
    }
    select {
      padding: 0px 10px;
    }
    .input-icon-container {
      position: relative;
      i {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
  }
}

form {
  &.form-company {
    flex-direction: column !important;
  }
  .form-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 0px;
    gap: 20px;
    .form-row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 5px;
      &.switch-row {
        width: 330px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        p {
          display: flex;
          flex-direction: column;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
        }
      }
      p {
        font-style: italic;
        color: #525151;
        font-size: 12px;
        font-weight: 400;
        label {
          font-style: normal;
          margin-right: 10px;
          font-weight: 600;
          font-size: 12px;
          line-height: 17px;
          color: black;
          position: relative;
          &.required {
            &::after {
              content: "*";
              position: absolute;
              right: -10px;
              color: #ce2b37;
            }
          }
        }
      }
      .form-error {
        width: 100%;
        height: 20px;
        color: #ce2b37;
        font-size: 12px;
        display: flex;
        flex-direction: column;
      }
      input,
      .input-type-file {
        width: 330px;
        height: 40px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 0px 10px;
        border: 1px solid #cecccc;
        background-color: white;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;

        &[type="file"] {
          display: none;
        }
        &.error {
          border: 1px solid #d66a71;
          background: #d66a7126;
        }
        .input-type-file-button {
          width: 110px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          text-transform: uppercase;
          background: #f9f7f7;
          border-left: 2px solid #cecccc;
          border-radius: 0px 3px 3px 0px;
          -webkit-border-radius: 0px 3px 3px 0px;
          -moz-border-radius: 0px 3px 3px 0px;
          -ms-border-radius: 0px 3px 3px 0px;
          -o-border-radius: 0px 3px 3px 0px;
        }

        &:disabled {
          color: #c3c3c3c3;
          cursor: not-allowed;
        }
      }
      .input-type-file {
        font-size: 12px;
        font-weight: 400;
        color: #cecccc;
        #inputLogoCompanyText.uploaded {
          color: black;
        }
      }
      .input-type-password {
        position: relative;
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          background: url("./assets/img/svg/icons/icon-password.svg") no-repeat
            center;
          position: absolute;
          left: 10px;
          top: 10px;
        }
        input {
          padding-left: 40px;
        }
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 25px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: #64b7e3;
    }
    &:focus + .slider {
      box-shadow: 0px 0px 1px #64b7e3;
    }
    &:checked + .slider::before {
      transform: translateX(20px);
      -webkit-transform: translateX(20px);
      -moz-transform: translateX(20px);
      -ms-transform: translateX(20px);
      -o-transform: translateX(20px);
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    &::before {
      position: absolute;
      content: "";
      height: 21px;
      width: 21px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.5s;
      -webkit-transition: 0.5s;
      -moz-transition: 0.5s;
      -ms-transition: 0.5s;
      -o-transition: 0.5s;
    }
    &.round {
      border-radius: 34px;
      -webkit-border-radius: 34px;
      -moz-border-radius: 34px;
      -ms-border-radius: 34px;
      -o-border-radius: 34px;
      &::before {
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
    }
  }
}

.button {
  cursor: pointer;
  min-width: 120px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0px 10px 0px 30px;
  font-size: 12px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  &.btn-td {
    height: 30px;
  }
  &.btn-red {
    background: $primary-color;
    color: white;
  }
  &.btn-form {
    width: auto !important;
    min-width: 0;
    height: auto !important;
    background-color: $primary-color;
    color: white;
    // font-weight: normal;
    // text-transform: capitalize;
    padding: 5px 10px;
  }
  &.btn-grey {
    background-color: #e5e4e4;
    color: black;
  }
  &.btn-excel {
    background-color: #5f8b73;
    color: white;
    margin-bottom: 10px;
    background-image: url("./assets/img/svg/icons/icon-excel.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 10px;
  }
  &.btn-transparent {
    background: none;
    color: black;
  }
  &.btn-save {
    background-image: url("./assets/img/svg/icons/icon-check-white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 10px;
  }
  &.btn-back {
    background-image: url("./assets/img/svg/icons/icon-arrow-left.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 10px;
  }
  &.btn-add {
    background-image: url("./assets/img/svg/icons/icon-add.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 5px;
  }
  &.btn-disable {
    background-image: url("./assets/img/svg/icons/icon-disable.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 5px;
  }
  &.btn-edit {
    background-image: url("./assets/img/svg/icons/icon-eddit.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 5px;
    &.black {
      background-image: url("./assets/img/svg/icons/icon-edit-black.svg");
    }
  }
  &.btn-details {
    background-image: url("./assets/img/svg/icons/icon-details.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 10px;
  }
  &.btn-filter {
    background-image: url("./assets/img/svg/icons/icon-filter.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 15px;
  }
  &.btn-search {
    background-image: url("./assets/img/svg/icons/icon-search.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 15px;
  }
  &.btn-clipboard {
    background-image: url("./assets/img/svg/icons/icon-clipboard-white.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 15px;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.btn-no-icon {
    padding: 0;
  }
  &.btn-full-w {
    width: 100% !important;
  }
}

.icon-password {
  width: 19px;
  height: 19px;
  background: url("./assets/img/svg/icons/icon-password.svg") no-repeat center;
}

.icon-user {
  width: 19px;
  height: 19px;
  background: url("./assets/img/svg/icons/icon-user.svg") no-repeat center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  .col {
    flex-basis: 50%;
  }
}

.drop-open-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .drop-open-list {
    width: 100%;
    height: 54px;
    border: 1px solid #ebebeb;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0px 20px;
    &.header {
      background: #f9f7f7;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        width: 5px;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #ce2b37;
      }
      &:hover {
        background: #e2e1e1;
      }
      &.disabled {
        background: #dcdcdc;
        .icon-name-container {
          opacity: 0.5;
        }
      }
    }
    &.child {
      display: none;
      &.open {
        display: flex;
      }
      &.disabled {
        background: #dcdcdc;
        .icon-name-container {
          opacity: 0.5;
        }
      }
    }
    .icon-name-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      .drop-open-list-name {
        font-weight: 400;
        font-size: 12px;
        color: black;
        padding-top: 5px;
        &.title {
          font-weight: 600;
        }
      }
    }
  }
}

.icon {
  width: 20px;
  height: 20px;

  &.icon-open-list-child {
    margin-left: 30px;
  }
  &.icon-location {
    background: url("./assets/img/svg/icons/icon-location.svg") no-repeat center;
    background-size: contain;
  }
  &.icon-readpoint {
    background: url("./assets/img/svg/icons/icon-readpoint.svg") no-repeat
      center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
  &.icon-pallet {
    background: url("./assets/img/svg/icons/icon-pallet.svg") no-repeat center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
  &.icon-date {
    background: url("./assets/img/svg/icons/icon-date.svg") no-repeat center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
  &.icon-doc {
    background: url("./assets/img/svg/icons/icon-doc.svg") no-repeat center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
  &.icon-destination {
    background: url("./assets/img/svg/icons/icon-destination.svg") no-repeat
      center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
  &.icon-close {
    background: url("./assets/img/svg/icons/icon-close.svg") no-repeat center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
  &.icon-warning {
    background: url("./assets/img/svg/icons/icon-warning.svg") no-repeat center;
    background-size: contain;
  }
  &.icon-box {
    background: url("./assets/img/svg/icons/icon-package.svg") no-repeat center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
  &.icon-item {
    background: url("./assets/img/svg/icons/icon-rhombus.svg") no-repeat center;
    background-size: contain;
    width: 15px;
    height: 15px;
  }
  &.icon-arrow-down {
    background: url("./assets/img/svg/icons/icon-arrow-down.svg") no-repeat
      center;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
  &.icon-x {
    display: block;
    width: 30px;
    height: 30px;
    background: url("./assets/img/svg/icons/icon-x.svg") no-repeat center;
  }
  &.big {
    width: 64px;
    height: 64px;
  }
  &.medium {
    width: 32px;
    height: 32px;
  }
}

.content-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  border-top: 2px solid #cfcfcf;
  padding: 10px 0px;
  margin-top: 10px;
}

.table-container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
  
  border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.table {
  width: 100%;
  min-height: 47px;
  border-collapse: collapse;
  background-color: #f9f7f7;
  
  border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -ms-border-radius: 10px 10px 0px 0px;
  -o-border-radius: 10px 10px 0px 0px;
  thead {
    height: 47px;
    th {
      font-weight: 700;
      font-size: 14px;
      color: black;
      text-align: left;
      padding: 0 10px;
    }
  }
  td {
    text-align: left;
    padding: 0 10px;
    height: 40px;
    font-size: 12px;
    &.td-checkbox {
      width: 60px;
      text-align: center;
    }
    .icon-check,
    .icon-x {
      display: block;
      text-align: center;
      margin: 0 auto;
    }
    .td-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      .button {
        width: 120px;
      }
    }
  }
  tbody {
    tr {
      border: 1px solid #ebebeb;
      &:nth-child(odd) td {
        background-color: white;
      }
      &:nth-child(even) td {
        background-color: #f9f7f7;
      }
    }
  }
  .form-checkbox {
    width: 15px;
    height: 15px;
  }
}
.icon-check {
  display: block;
  width: 30px;
  height: 30px;
  background: url("./assets/img/svg/icons/icon-tick.svg") no-repeat center;
}

.icon-copy {
  display: block;
  width: 20px;
  height: 20px;
  background: url("./assets/img/svg/icons/icon-copy.svg") no-repeat center;
  background-size: contain;
}

.icon-expand-more {
  display: block;
  width: 20px;
  height: 20px;
  background: url("./assets/img/svg/icons/expand_more.svg") no-repeat center;
  background-size: contain;
}
.icon-chevron-right {
  display: block;
  width: 20px;
  height: 20px;
  background: url("./assets/img/svg/icons/chevron_right.svg") no-repeat center;
  background-size: contain;
}

.df-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.df-row {
  display: flex;
  flex-direction: row !important;
}

.filter-container {
  width: 100%;
  background: #f9f7f7;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-top: -10px;
  position: relative;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  .filter-form {
    flex-wrap: wrap;
    /* justify-content: space-between; */
    align-items: end;
    .form-row {
      width: fit-content !important;
      min-width: 330px;
    }
    .form-input-date-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .input-date-filter {
        width: 150px;
        &.left {
          border-radius: 5px 0px 0px 5px;
          -webkit-border-radius: 5px 0px 0px 5px;
          -moz-border-radius: 5px 0px 0px 5px;
          -ms-border-radius: 5px 0px 0px 5px;
          -o-border-radius: 5px 0px 0px 5px;
        }
        &.right {
          border-radius: 0px 5px 5px 0px;
          -webkit-border-radius: 0px 5px 5px 0px;
          -moz-border-radius: 0px 5px 5px 0px;
          -ms-border-radius: 0px 5px 5px 0px;
          -o-border-radius: 0px 5px 5px 0px;
        }
      }
      .date-separator {
        width: 34px;
        height: 42px;
        background: #f4f4f4;
        border-width: 2px 0px;
        border-style: solid;
        border-color: #cecccc;
        background: url("./assets/img/svg/icons/icon-date-arrow.svg") no-repeat
          center;
      }
    }
  }
}

.shipment-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background: #f9f7f7;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-top: -10px;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  .row {
    .col {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      flex-basis: 0 !important;
      min-width: 300px;
    }
    .desc {
      min-width: fit-content !important;
      font-size: 14px;
    }
    .fb {
      font-weight: bold;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  width: 15px;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.error-message-container {
  width: 100%;
  min-height: 42px;
  border: 2px #c3c3c3c3;
  background: #c53e47;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  &::after {
    content: "";
    background: #c53e47;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 8px;
    z-index: 2;
    border-radius: 5px 0px 0px 5px;
    -webkit-border-radius: 5px 0px 0px 5px;
    -moz-border-radius: 5px 0px 0px 5px;
    -ms-border-radius: 5px 0px 0px 5px;
    -o-border-radius: 5px 0px 0px 5px;
  }
  color: white;
  font-weight: bold;
}

.success-message-container {
  width: 100%;
  min-height: 42px;
  border: 2px #c3c3c3c3;
  background: $success-color;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  &::after {
    content: "";
    background: $success-color;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 8px;
    z-index: 2;
    border-radius: 5px 0px 0px 5px;
    -webkit-border-radius: 5px 0px 0px 5px;
    -moz-border-radius: 5px 0px 0px 5px;
    -ms-border-radius: 5px 0px 0px 5px;
    -o-border-radius: 5px 0px 0px 5px;
  }
  color: white;
  font-weight: bold;
}

/* Allows to change the color of an icon to white */
.white {
  filter: brightness(100);
  -webkit-filter: brightness(100);
}
/* Allows to change the color of an icon to black */
.black {
  filter: brightness(0);
  -webkit-filter: brightness(0);
}


.orderby {
  cursor: pointer;
  span {
    width: 100%;
    display: inline-block;
    position: relative;
    &::before {
      content: "↑";
      position: absolute;
      right: 12px;
      height: 13.33px;
      top: 0;
      bottom: 5px;
      margin: auto 0;
      opacity: 0.5;
    }
    &::after {
      content: "↓";
      position: absolute;
      right: 5px;
      height: 13.33px;
      top: 5px;
      bottom: 0;
      margin: auto 0;
      opacity: 0.5;
    }
    &.selected {
      position: relative;
      &::before {
        content: "↑";
        position: absolute;
        right: 12px;
        opacity: 1;
      }
      &::after {
        content: "↓";
        position: absolute;
        right: 5px;
        opacity: 0.5;
      }
      &.asc {
        &::before {
          content: "↑";
          position: absolute;
          right: 12px;
          opacity: 0.5;
        }
        &::after {
          content: "↓";
          position: absolute;
          right: 5px;
          opacity: 1;
        }
      }
    }
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

.table-footer {
  padding: 10px 0px;
}

.td-min-size {
  width: 10px;
}

.td-number {
  text-align: right !important;
}

.filter-button {
  margin-left: auto;
}

input[disabled] {
  background-color: #e9e9e9 !important;
  cursor: not-allowed !important;
}

.app-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 70px auto;
  grid-template-columns: 225px auto;
  grid-template-areas:
    "header header"
    "side content-container";

  .header-container {
    grid-area: header;
  }
  .side-container {
    grid-area: side;
  }
  .content-container {
    grid-area: content-container;
    background: $secondary-color;
    padding: 30px;
    display: grid;
    grid-template-rows: 70px auto;
    grid-template-columns: 100%;
    grid-template-areas:
      "breadcrumb"
      "content";
  }
}

.content-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  border-top: 2px solid #cfcfcf;
  padding: 10px 0px;
}

.form-registry-company {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "companyLogo"
    "form";

  .form-company-logo {
    grid-area: companyLogo;
    img {
      width: 104px;
      height: 104px;
    }
  }
}

.form-company {
  flex-direction: column !important;
}

.input-codeVericode {
  position: relative;
  .input-vericodeV {
    width: 56px;
    height: 36px;
    position: absolute;
    top: 2px;
    left: 2px;
    color: black;
    font-size: 14px;
    font-weight: 600;
    background: #f9f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px solid #cecccc;
    border-radius: 3px 0px 0px 3px;
    -webkit-border-radius: 3px 0px 0px 3px;
    -moz-border-radius: 3px 0px 0px 3px;
    -ms-border-radius: 3px 0px 0px 3px;
    -o-border-radius: 3px 0px 0px 3px;
  }
  input {
    padding-left: 65px !important;
  }
}

.form-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 0px;
  gap: 20px;
  .form-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    p {
      font-style: italic;
      color: #525151;
      font-size: 12px;
      font-weight: 400;
      label {
        font-style: normal;
        margin-right: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: black;
        position: relative;
        &.required {
          &::after {
            content: "*";
            position: absolute;
            right: -10px;
            color: #ce2b37;
          }
        }
      }
    }
    input,
    .input-type-file {
      width: 330px;
      height: 40px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px 0px 10px;
      border: 2px solid #cecccc;
      background-color: white;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;

      &[type="file"] {
        display: none;
      }
      .input-type-file-button {
        width: 110px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        background: #f9f7f7;
        border-left: 2px solid #cecccc;
        border-radius: 0px 3px 3px 0px;
        -webkit-border-radius: 0px 3px 3px 0px;
        -moz-border-radius: 0px 3px 3px 0px;
        -ms-border-radius: 0px 3px 3px 0px;
        -o-border-radius: 0px 3px 3px 0px;
      }
    }
    .input-type-file {
      font-size: 12px;
      font-weight: 400;
      color: #cecccc;
      #inputLogoCompanyText.uploaded {
        color: black;
      }
    }
  }
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  border-bottom-color: #0000001f;
}

.text-center {
  text-align: center;
}  

.spacer {
  flex: 1 1 0;
}

@media only screen and (max-width: 1140px) {
  .breadcrumb-item {
    &.empty {
      display: none !important;
    }
  }
  .row {
    flex-direction: column;
    align-items: flex-start;
  }
  .col {
    flex-basis: 100%;
  }
  .filter-form {
    display: flex;
    flex-direction: row;
  }
  .form-row {
    flex: 1 1 0;
  }
}

@media screen and (max-width: 1050px) {
  .filter-container .filter-form .form-row {
    width: 100% !important;
  }
  form .form-row input,
  form .form-row select {
    width: 100%;
  }
  .res-input {
    width: 100% !important;
    .fake-input {
      width: 100% !important;
    }
  }
  .res-buttons {
    display: flex !important;
    justify-content: space-between !important;
  }
  .item-code-input {
    width: 100% !important;
  }
  .row .col {
    flex-basis: 100%;
  }
}
